import React from 'react'
import '../../components/styles/global.scss'
import SegmentedControl from '../../components/common/SegmentedControl'
import Layout from '../../components/common/Layout'
import { useStaticQuery, graphql } from 'gatsby'

export default function ResourcesLinks() {
    const data = useStaticQuery(graphql`
                query Links {
                allDataYaml(filter: {yamlId: {eq: "useful-links"}}) {
                    nodes {
                    links {
                        label
                        url
                    }
                    }
                }
                }
            `),
            links = data.allDataYaml.nodes[0].links

    return (
        <Layout active='health-org-links'
        title='PharmaEssentia Medical Affairs | Health Organization Links'
        description='Links to educational resources that can help healthcare professionals stay up to date on myeloproliferative neoplasms.'
        socialTitle='PharmaEssentia Medical Affairs | Health Organization Links'
        socialDescription='Links to educational resources that can help healthcare professionals stay up to date on myeloproliferative neoplasms.'
        pathname='/resources/health-org-links'
        >
            <section>
                <div className='inner'>
                    <h1>Resources</h1>
                    <SegmentedControl additionalClasses='space-between' buttons='resources' active='health-org-links'/>
                    <h3>Health Organization Links</h3>
                    <div id='results'>
                        {
                            links.map((item, index)=>(
                                <React.Fragment key={index + 'fragment'}>
                                    <a 
                                    href={item.url}
                                    target='_blank'
                                    key={index + 'a'}
                                    rel='noopener noreferrer'
                                    className='resources-results'
                                    dangerouslySetInnerHTML={{__html: item.label + ' <span class="triangle"></span>'}}
                                    />
                                    {
                                        index + 1 !== links.length && <span key={index + 'divider'} className='divider'/>
                                    }
                                </React.Fragment>
                            ))
                        }
                    </div>
                </div>
            </section>
        </Layout>
    )
}